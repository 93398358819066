
import { BaseUrl } from '../../environments/environment';

export class Settings {
    // public apiBaseUrl = 'http://localhost:8088/dreptullaviataapi';
    // public apiBaseUrl = 'http://dreptullaviata.aiso.ro/api';

    //#region Authenticate
    public authenticateUrl = BaseUrl.value + '/user/authenticate.php';
    //
    //#region Users
    public usersUrl = BaseUrl.value + '/user-portal/getAll.php';
    public userAddUrl = BaseUrl.value + '/user-portal/addUser.php';
    //

    //#region Images
    public imagesUrl = BaseUrl.value + '/controllers/ImageController.php'; // '/images-portal/getAll.php';
    public deleteimage = BaseUrl.value + '/controllers/ImageController.php?';
    //

    //#region
    public pagesUrl = BaseUrl.value + '/controllers/PageController.php'; // '/images-portal/getAll.php';
    //

}
