import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuComponent } from './header/menu/menu.component';
import { SideNavComponent } from './header/side-nav/side-nav.component';
import { UserToolbarComponent } from './header/user-toolbar/user-toolbar.component';
import { AppMaterialModule } from './material/app.material';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
  declarations: [MenuComponent, SideNavComponent, UserToolbarComponent, LoadingComponent],
  imports: [
    CommonModule,
    BreadcrumbModule,
    AppMaterialModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
  ],
  exports: [
    BreadcrumbModule,
    MenuComponent, SideNavComponent, UserToolbarComponent, LoadingComponent
  ],
  providers: []
})
export class CoreModule { }
