import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '../models/user';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Settings } from './../../core/app.settings';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SpinnerService } from './SpinnerService';
import { CommonService } from './CommonService';

import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {
    private settings = new Settings();
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private loggedIn = new BehaviorSubject<boolean>(false); // {1}

    get isLoggedIn() {
        return this.loggedIn.asObservable(); // {2}
    }

    constructor(private http: HttpClient
        , private spinnerService: SpinnerService
        , private commonService: CommonService
        , private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(new User());
        // (localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : '');
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        this.loggedIn.next(false);

        // const helper = new JwtHelperService();
        // const myRawToken =
        //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
        // eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        // const decodedToken = helper.decodeToken(myRawToken);
        // const expirationDate = helper.getTokenExpirationDate(myRawToken);
        // const isExpired = helper.isTokenExpired(myRawToken);


        // return this.http.post<User[]>(this.settings.authenticateUrl, { username, password })
        return this.http.get<User[]>(this.settings.authenticateUrl,
            { params: { username: username, password: password } }
        )
            .pipe(map((user: User[]) => {
                // login successful if there's a jwt token in the response
                // && user[0].token
                if (user && user.length > 0) {
                    if (user[0].Id > 0) { // {3}
                        this.loggedIn.next(true);
                    }
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('access_token', JSON.stringify(user));
                    this.currentUserSubject.next(user[0]);
                }

                this.spinnerService.hide();
                return user;
            }), catchError(this.commonService.handleError));

    }

    logout() {
        this.loggedIn.next(false);

        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
        this.currentUserSubject.next(null); // {4}
        this.router.navigate(['/login']);
    }
}
