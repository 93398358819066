import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './core/header/header.component';
import { ContentComponent } from './core/content/content.component';
import { FooterComponent } from './core/footer/footer.component';
import { AppMaterialModule } from './core/material/app.material';
// import {
//   MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCardModule, MatMenuModule
//   , MatSlideToggleModule, MatFormFieldModule, MatInputModule, MatTableModule, MatRadioModule, MatDatepickerModule
//   , MatNativeDateModule, MatTreeModule, MatExpansionModule
// } from '@angular/material';
// import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HomeComponent } from './frontoffice/home/home.component';
import { AboutComponent } from './frontoffice/about/about.component';
import { ContactComponent } from './frontoffice/contact/contact.component';
import { ProjectsComponent } from './frontoffice/projects/projects.component';
import { CenterComponent } from './frontoffice/center/center.component';
import { ChristmasComponent } from './frontoffice/christmas/christmas.component';
import { DonateComponent } from './frontoffice/donate/donate.component';
import { LoginComponent } from './frontoffice/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
// import { } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleriaModule } from 'primeng/galleria';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataViewModule } from 'primeng/dataview';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';

import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

import { ImagesService } from './frontoffice/services/ImagesService';
import { HomeSettingsComponent } from './backoffice/administration/administration.component';
import { RegisterComponent } from './frontoffice/register/register.component';
import { AuthenticationService } from './frontoffice/services/AuthenticateService';
import { SpinnerService } from './frontoffice/services/SpinnerService';
import { CommonService } from './frontoffice/services/CommonService';

import { UserService } from './frontoffice/services/UserService';
import { ErrorInterceptor } from './core/shared/interceptor.service';
import { ValidatorService } from './core/shared/validator.service';

import { AuthGuard } from './core/shared/authGuard.service';
import { CarouselModule } from 'primeng/carousel';
import { BackofficeModule } from './backoffice/backoffice.module';
import { CoreModule } from './core/core.module';
import { AdminHomeComponent } from './backoffice/admin-home/admin-home.component';
import { AdminUsersComponent } from './backoffice/admin-users/admin-users.component';
import { AdminAboutComponent } from './backoffice/admin-about/admin-about.component';
import { AdminChildrenComponent } from './backoffice/admin-children/admin-children.component';
import { AdminChristmasComponent } from './backoffice/admin-christmas/admin-christmas.component';
import { AdminContactComponent } from './backoffice/admin-contact/admin-contact.component';
import { AdminDonateComponent } from './backoffice/admin-donate/admin-donate.component';
import { AdminProjectsComponent } from './backoffice/admin-projects/admin-projects.component';
import { AdminCenterComponent } from './backoffice/admin-center/admin-center.component';
import { AdminFooterComponent } from './backoffice/admin-footer/admin-footer.component';

import { PageService } from './frontoffice/services/PagesServices';
import { EscapeHtmlPipe } from './core/pipes/EscapeHtmlPipe';
// Import Angular plugin.
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ArticlesComponent } from './frontoffice/about/articles/articles.component';
import { DescriptionComponent } from './frontoffice/about/description/description.component';
import { SideNavService } from './frontoffice/services/SideNavService';

// import { AngularSocialPlugins } from 'angular-social-plugins';
// import {TreeModule} from 'primeng/tree';
// import {TreeNode} from 'primeng/api';

@NgModule({
  declarations: [
    EscapeHtmlPipe,
    AppComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    ProjectsComponent,
    CenterComponent,
    ChristmasComponent,
    DonateComponent,
    LoginComponent,
    HomeComponent,
    HomeSettingsComponent,
    RegisterComponent,
    AdminHomeComponent,
    AdminUsersComponent,
    AdminAboutComponent,
    AdminFooterComponent,
    AdminChildrenComponent,
    AdminChristmasComponent,
    AdminContactComponent,
    AdminDonateComponent,
    AdminProjectsComponent,
    AdminCenterComponent,

    ArticlesComponent,
    DescriptionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    // MatToolbarModule,
    // MatButtonModule,
    // MatSidenavModule,
    // MatIconModule,
    // MatListModule,
    // MatCardModule,
    // MatMenuModule,
    // MatSlideToggleModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatRadioModule,
    // MatTableModule,
    // MatDatepickerModule,
    // MatNativeDateModule,        // <----- import for date formating(optional)
    // MatMomentDateModule,        // <----- import for date formating adapted to more locales(optional)
    // MatTreeModule,
    // MatExpansionModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    FlexLayoutModule,
    NgbModule,
    GalleriaModule,
    FormsModule,
    CommonModule,
    // CKEditorModule,
    DataViewModule,
    PanelModule,
    DialogModule,
    ReactiveFormsModule,
    FileUploadModule,
    // AngularSocialPlugins,

    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    CarouselModule,
    BackofficeModule,
    CoreModule,
    // TreeModule,
    ConfirmDialogModule,
    // ConfirmationService,
    ToastrModule.forRoot(), // ToastrModule added
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  exports: [
    // MatButtonModule,
    // MatToolbarModule,
    // MatIconModule,
    // MatCardModule,
    // MatMenuModule,
    // MatSlideToggleModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatTreeModule,
    AppMaterialModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    DataViewModule,
    PanelModule,
    DialogModule,
    BackofficeModule,
    ConfirmDialogModule,
    // AngularSocialPlugins,

    // ConfirmationService
    // TreeModule
  ],
  providers: [
    SpinnerService,
    CommonService,
    UserService,
    AuthenticationService,
    ImagesService,
    PageService,
    AuthGuard,
    ValidatorService,
    ConfirmationService,
    SideNavService,
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
