import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';

import { PageModel } from '../../models/PageModel';
import { SpinnerService } from '../../services/SpinnerService';
import { UserService } from '../../services/UserService';
import { PageService } from '../../services/PagesServices';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  private success: string;
  private error: string;
  private editorContent: '';
  private aboutPage: PageModel;

  constructor(private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
  ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    // about page
    this.pageService.getPageById(3).subscribe(
      res => {
        this.aboutPage = res[0];
        this.editorContent = res[0].Content.toString();
      });
  }
  // ngAfterViewInit(): void {
  //   this.spinnerService.hide();
  // }
  // ngOnDestroy() {
  // }

}
