import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { AuthenticationService } from 'src/app/frontoffice/services/AuthenticateService';
import { NavigationEnd, Router } from '@angular/router';
import { User } from 'src/app/frontoffice/models/user';
import { Observable, Subscription } from 'rxjs';
import { SideNavService } from 'src/app/frontoffice/services/SideNavService';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  currentUser: User;
  isLoggedIn: Observable<Boolean>;
  subscription: Subscription;
  opened: boolean;

  constructor(private authService: AuthenticationService, private router: Router, private sideNavService: SideNavService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.sidenav.close();

        this.currentUser = this.authService.currentUserValue;
        if (this.currentUser) {
          this.isLoggedIn = this.authService.isLoggedIn; // {2}
          return true;
        }
      }
    });
  }

  ngOnInit() {
    this.opened = true;

    // this.subscription = this.sideNavService.sideNavActive.subscribe((val: boolean) => {
    //   debugger
    //   this.opened = val;
    // });
  }
  toggle() {
    debugger
    this.opened = !this.opened;
  }

}
