import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { SpinnerService } from './SpinnerService';
import { Settings } from '../../core/app.settings';
import { catchError, map, finalize } from 'rxjs/operators';
import { ImageModel } from '../models/ImageModel';
import { CommonService } from './CommonService';

@Injectable()
export class ImagesService implements OnInit, OnDestroy {
    private images: ImageModel[];
    private settings = new Settings();
    public headers: HttpHeaders = new HttpHeaders();

    constructor(private http: HttpClient, private spinnerService: SpinnerService, private commonService: CommonService) {
        // this.headers.append('Access-Control-Allow-Origin', '*');
    }

    ngOnDestroy(): void {
    }
    ngOnInit(): void {
    }

    addImage(image: ImageModel, files: any): Observable<ImageModel[]> {
        const uploadFormData = new FormData();
        uploadFormData.append('myFile', files, files.name);

        const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data; charset=utf-8');
        const params = { headers, responseType: 'text' };
        // headers.set('Content-length', params[0].length);
        return this.http.post(this.settings.imagesUrl, uploadFormData )
            .pipe(map((res: ImageModel) => {
                this.images.push(res);
                return this.images;
            }),
                catchError(this.commonService.handleError));
    }

    getImages(): Observable<ImageModel[]> {
        if (this.spinnerService) {
            this.spinnerService.show();
        }
        return this.http.get(this.settings.imagesUrl)
            .pipe(
                map((res: ImageModel[]) => {
                    this.images = res; // res['data'];
                    if (this.spinnerService) {
                        this.spinnerService.hide();
                    }
                    return this.images;
                }), catchError(this.commonService.handleError));

    }

    deleteImage(id: number): Observable<ImageModel[]> {
        const deleteUrl = this.settings.deleteimage;

        const params = new HttpParams()
            .set('Id', id.toString());

        return this.http.delete(deleteUrl, { params: params })
            .pipe(map(res => {
                const filteredImages = this.images.filter((image) => {
                    return +image['Id'] !== +id;
                });
                return this.images = filteredImages;
            }),
                catchError(this.commonService.handleError));
    }

    // private handleError(error: HttpErrorResponse) {
    //     if (error.error instanceof ErrorEvent) {
    //         // A client-side or network error occurred. Handle it accordingly.
    //         console.error('An error occurred:', error.error.message);
    //     } else {
    //         // The backend returned an unsuccessful response code.
    //         // The response body may contain clues as to what went wrong,
    //         // console.error(
    //         //     `Backend returned code ${error.status}, ` +
    //         //     `body was: ${error.error}`);
    //     }
    //     if (this.spinnerService) {
    //         this.spinnerService.hide();

    //     }
    //     // return an observable with a user-facing error message
    //     return throwError(
    //         'Something bad happened; please try again later.');
    // }
}
