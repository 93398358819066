import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/frontoffice/services/SpinnerService';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
@ViewChild('spinnerElement')
export class LoadingComponent implements OnInit {
  private spinnerElement: ElementRef;
  private subscription: Subscription;
  loading: boolean;

  constructor(private spinnerService: SpinnerService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    // this.loading = true;
    // this.spinnerService.triggerEventFn(true);
    this.subscription = this.spinnerService.spinnerActive.subscribe((val: boolean) => {
      this.loading = val;
    });

  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    // debugger
    // this.loading = false;
    // this.changeDetector.detectChanges();
  }

}
