import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/frontoffice/services/ImagesService';
import { SpinnerService } from 'src/app/frontoffice/services/SpinnerService';
import { ImageModel } from 'src/app/frontoffice/models/ImageModel';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { PageModel } from 'src/app/frontoffice/models/PageModel';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
import { Message, ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  public options: Object = {
    charCounterCount: true,
    // Set the image upload parameter.
    imageUploadParam: 'image_param',

    // Set the image upload URL.
    imageUploadURL: '../shared/upload.php', // 'assets/images/portfolio',

    // Additional upload params.
    imageUploadParams: { id: 'file' },

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'txt'],
    events: {
      'froalaEditor.initialized': function () {
        console.log('initialized');
      },
      'froalaEditor.image.beforeUpload': function (e, editor, images) {
        if (images.length) {
          // Create a File Reader.
          const reader = new FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload = (ev) => {
            const result = ev.target['result'];
            editor.image.insert(result, null, null, editor.image.get());
            console.log(ev, editor.image, ev.target['result']);
          };
          // Read image as base64.
          reader.readAsDataURL(images[0]);
        }
      },
      'froalaEditor.image.uploaded': function (e, editor, response) {
        // Image was uploaded to the server.
      },
      'froalaEditor.image.inserted': function (e, editor, $img, response) {
        // Image was inserted in the editor.
      },
      'froalaEditor.image.replaced': function (e, editor, $img, response) {
        // Image was replaced in the editor.
      },
      'froalaEditor.image.error': function (e, editor, error, response) {
        // Bad link.
        if (error.code === 1) {
          console.log('Bad link');
          // Bad link.
        } else if (error.code === 2) {
          // // No link in upload response.
          console.log('No link in upload response.');
        } else if (error.code === 3) {
          // // Error during image upload.
          console.log('Error during image upload.');

        } else if (error.code === 4) {
          console.log('Parsing response failed.');

          // // Parsing response failed.

        } else if (error.code === 5) {
          // // Image too text-large.
          console.log('Image too text-large.');


        } else if (error.code === 6) {
          // // Invalid image type.
          console.log('Invalid image type.');

        } else if (error.code === 7) {
          // // Image can be uploaded only to same domain in IE 8 and IE 9.
          console.log('Image can be uploaded only to same domain in IE 8 and IE 9.');

        }

        // Response contains the original server response to the request if available.
      }
    }
  };

  public editorValue = '';
  private images = [];
  private pages: PageModel[];
  private ckeConfig: any;
  private toastrConfig: any;
  msgs: Message[] = [];

  success: string;
  error: string;

  constructor(private imagesService: ImagesService
    , private pageService: PageService
    , private spinnerService: SpinnerService
    , private toastrService: ToastrService
    , private confirmationService: ConfirmationService

  ) {
    this.toastrConfig = { positionClass: 'toast-top-center' };
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.imagesService.getImages()
      .subscribe(
        res => {
          this.images = res;
        });
    this.pageService.getPages()
      .subscribe(
        res => {
          this.pages = res;
          this.editorValue = this.pages[0].Motto;
        });
  }

  updatePage(page: PageModel) {
    this.success = '';
    this.error = '';
    const homePage = new PageModel();
    homePage.Id = 1;
    homePage.Motto = this.editorValue;
    homePage.Text1 = '';
    homePage.Text2 = '';
    homePage.Text3 = '';

    this.pageService.updatePage(homePage)
      .subscribe(
        (res) => {
          this.pages = res;
          this.success = 'Updated successfully';
        },
        (err) => this.error = err
      );
    this.toastrService.success('Motto a fost salvat cu succes.', 'Succes!', this.toastrConfig);
  }

  addMotto(event, image: ImageModel) {
    this.error = '';
    this.success = '';
    if (event.files) {
      const fileToUpload = event.files[0];
      const imageToAdd = new ImageModel();
      imageToAdd.Path = event.files[0].name;
      // const input = new FormData();

      this.imagesService.addImage(imageToAdd, event.files[0])
        .subscribe(
          (res: ImageModel[]) => {
            // Update the list of cars
            this.images = res;

            // Inform the user
            this.success = 'Created successfully';
            this.toastrService.success('Motto a fost salvat cu succes.', 'Succes!', this.toastrConfig);

            // Reset the form
            // f.reset();
          },
          (err) => this.error = err
        );
    }

  }
  addImage(event, image: ImageModel) {
    this.error = '';
    this.success = '';
    if (event.files) {
      const fileToUpload = event.files[0];
      const imageToAdd = new ImageModel();
      imageToAdd.Path = event.files[0].name;
      // input.append('file', fileToUpload);
      // const formData: FormData = new FormData();
      // formData.append('file', fileToUpload, fileToUpload.name);

      this.imagesService.addImage(imageToAdd, event.files[0])
        .subscribe(
          (res: ImageModel[]) => {
            // Update the list of cars
            this.images = res;

            // Inform the user
            this.success = 'Created successfully';

            // Reset the form
            // f.reset();
          },
          (err) => this.error = err
        );
    }

  }

  deleteImage(event, image: ImageModel) {

    // this.toastrService.confirm('Are you sure?', { onOk: () => { console.log('ok') }, onCancel: () => { console.log('cancel') } })

    this.success = '';
    this.error = '';

    this.imagesService.deleteImage(image.Id)
      .subscribe(
        (res: ImageModel[]) => {
          this.images = res;
          this.success = 'Deleted successfully';
        },
        (err) => this.error = err
      );
    const imageDel = this.images.indexOf(image);
    this.images.splice(imageDel, 1);
  }
  confirm(event, image: ImageModel) {
    this.confirmationService.confirm({
      message: 'Vreti sa stergeti?',
      header: 'Confirmare',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'DA',
      rejectLabel: 'NU',
      accept: () => {
        this.deleteImage(event, image);
        this.msgs = [{ severity: 'info', summary: 'Confirmare', detail: 'Inregistrarea a fost stearsa!' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Anulare', detail: 'Operatiunea a fost anulata!' }];
      }
    });
  }
  onSubmitTemplateBased() {
    // console.log(this.vm);
  }
}
