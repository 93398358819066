import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subscription, throwError, } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { User } from '../models/user';

import { SpinnerService } from '../services/SpinnerService';
import { Settings } from './../../core/app.settings';
import { CommonService } from './CommonService';


@Injectable()
export class UserService implements OnInit, OnDestroy {
  private settings = new Settings();
  private show = false;

  private serviceUrl = 'https://jsonplaceholder.typicode.com/users';

  // private serviceUrl = 'https://reqres.in/api/users?delay=3';
  private subscription: Subscription;
  isLogged: any;
  users: User[];

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  constructor(private http: HttpClient
    , private spinnerService: SpinnerService
    , private commonService: CommonService
  ) { }

  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();

  }
  //   getCarsSmall() {
  //     return this.http.get<any>('assets/data/cars-small.json')
  //         .toPromise()
  //         .then(res => <Car[]> res.data)
  //         .then(data => data);
  // }

  login(username: string, password: string) {

    this.spinnerService.show();
    return this.http.get<User[]>(this.settings.authenticateUrl, { params: { username: username, password: password } })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        // && user.token
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.setItem('currentUser', user[0].Email);
        }
        return user;
        // return user;
      }), catchError(this.commonService.handleError));
    this.spinnerService.hide();

  }


  // login(username, password) {

  //   const url = this.settings.authenticateUrl;

  //   return this.http.get(this.settings.authenticateUrl, { params: { username: username, password: password } })
  //     .pipe(
  //       map((response: Response) => response),
  //       // map((response: Response) => <User[]>response.json()),
  //       catchError(this.handleError),
  //       finalize(() => this.spinnerService.hide())
  //     ).subscribe((users: any) => this.users = users);
  //   // return this.users;
  //   // return this.http.get<User[]>(this.settings.authenticateUrl,  { params: { username: username, password: password } })
  //   //   .pipe(
  //   //     catchError(this.handleError)
  //   //   )

  //   //   .pipe(map(result => result),         // Log when response observable either completes or errors
  //   //     finalize(() => {
  //   //       this.spinnerService.hide();
  //   //     }));




  //   // // debugger
  //   // return this.isLogged;
  //   // return this.http.get<any>(this.settings.authenticateUrl)
  //   //   .pipe(catchError(this.handleError),
  //   //   .pipe(map(result => result),         // Log when response observable either completes or errors
  //   //     finalize(() => {
  //   //       debugger
  //   //       this.spinnerService.hide();
  //   //     })).subscribe((logged: any) => this.logged = logged);
  // }
  getUser(): Observable<User[]> {
    this.spinnerService.show();
    return this.http.get<User[]>(this.settings.usersUrl)
      .pipe(
        catchError(this.commonService.handleError)
      )

      .pipe(map(result => result),         // Log when response observable either completes or errors
        finalize(() => {
          this.spinnerService.hide();
        }));
    // .toPromise();
    // .then(this.extractData);

    // .map((res: Response) => res.json())
    // .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }
  extractData(res: Response): any {
    this.spinnerService.hide();
  }

  // private handleError(error: HttpErrorResponse) {
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //   }
  //   // return an observable with a user-facing error message
  //   return throwError(
  //     'Something bad happened; please try again later.');
  // }
}
