import { Component, OnInit } from '@angular/core';
import { PageModel } from '../models/PageModel';
import { UserService } from '../services/UserService';
import { SpinnerService } from '../services/SpinnerService';
import { PageService } from '../services/PagesServices';

@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss']
})
export class CenterComponent implements OnInit {
  private editorContent: '';
  private centerPage: PageModel;

  constructor(private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
    ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    // about page
    this.pageService.getPageById(5).subscribe(
      res => {
        this.centerPage = res[0];
        this.editorContent = res[0].Text1.toString();
      });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    this.spinnerService.hide();
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
  }
}
