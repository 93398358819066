import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './frontoffice/about/about.component';
import { ContactComponent } from './frontoffice/contact/contact.component';
import { ProjectsComponent } from './frontoffice/projects/projects.component';
import { CenterComponent } from './frontoffice/center/center.component';
import { ChristmasComponent } from './frontoffice/christmas/christmas.component';
import { DonateComponent } from './frontoffice/donate/donate.component';
import { LoginComponent } from './frontoffice/login/login.component';
import { HomeComponent } from './frontoffice/home/home.component';
import { HomeSettingsComponent } from './backoffice/administration/administration.component';
import { RegisterComponent } from './frontoffice/register/register.component';
import { AuthGuard } from './core/shared/authGuard.service';
import { AuthenticationService } from './frontoffice/services/AuthenticateService';
import { AdminHomeComponent } from './backoffice/admin-home/admin-home.component';
import { AdminUsersComponent } from './backoffice/admin-users/admin-users.component';
import { AdminAboutComponent } from './backoffice/admin-about/admin-about.component';
import { AdminFooterComponent } from './backoffice/admin-footer/admin-footer.component';
import { DescriptionComponent } from './frontoffice/about/description/description.component';
import { ArticlesComponent } from './frontoffice/about/articles/articles.component';
import { AdminChildrenComponent } from './backoffice/admin-children/admin-children.component';
import { AdminChristmasComponent } from './backoffice/admin-christmas/admin-christmas.component';
import { AdminContactComponent } from './backoffice/admin-contact/admin-contact.component';
import { AdminDonateComponent } from './backoffice/admin-donate/admin-donate.component';
import { AdminProjectsComponent } from './backoffice/admin-projects/admin-projects.component';
import { AdminCenterComponent } from './backoffice/admin-center/admin-center.component';

const routes: Routes = [
  // { path: 'home', component: HomeCom },
  { path: '', component: HomeComponent }, // , canActivate: [AuthGuard]
  { path: 'home', component: HomeComponent, }, // , canActivate: [AuthGuard]
  {
    path: 'about', component: AboutComponent,
    children: [
      { path: 'description', component: DescriptionComponent }, // , canActivate: [AuthGuard]
      { path: 'articles', component: ArticlesComponent }, // , canActivate: [AuthGuard]
    ]
  }, // , canActivate: [AuthGuard]
  { path: 'projects', component: ProjectsComponent, }, // , canActivate: [AuthGuard]
  { path: 'center', component: CenterComponent, }, // , canActivate: [AuthGuard]
  { path: 'christmas', component: ChristmasComponent, }, // , canActivate: [AuthGuard]
  { path: 'donate', component: DonateComponent, }, // , canActivate: [AuthGuard]
  { path: 'contact', component: ContactComponent, }, // , canActivate: [AuthGuard]
  { path: 'login', component: LoginComponent, }, // , canActivate: [AuthGuard]
  { path: 'register', component: RegisterComponent, }, // , canActivate: [AuthGuard]
  {
    path: 'backoffice/administration',
    component: HomeSettingsComponent,
    children: [
      { path: 'admin-about', component: AdminAboutComponent }, // , canActivate: [AuthGuard]
      { path: 'admin-center', component: AdminCenterComponent }, // , canActivate: [AuthGuard]]
      { path: 'admin-children', component: AdminChildrenComponent }, // , canActivate: [AuthGuard]]
      { path: 'admin-christmas', component: AdminChristmasComponent }, // , canActivate: [AuthGuard]]
      { path: 'admin-contact', component: AdminContactComponent }, // , canActivate: [AuthGuard]]
      { path: 'admin-donate', component: AdminDonateComponent }, // , canActivate: [AuthGuard]]
      { path: 'admin-footer', component: AdminFooterComponent }, // , canActivate: [AuthGuard]]
      { path: 'admin-home', component: AdminHomeComponent }, // , canActivate: [AuthGuard]
      { path: 'admin-projects', component: AdminProjectsComponent }, // , canActivate: [AuthGuard]]
      { path: 'admin-users', component: AdminUsersComponent }, // , canActivate: [AuthGuard]]
    ]
  }, // , canActivate: [AuthGuard]
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
