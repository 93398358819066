import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { NodeService } from '../core/shared/node.service';
// import { AdminProjectsComponent } from './admin-projects/admin-projects.component';
// import { AdminCenterComponent } from './admin-center/admin-center.component';
// import { AdminChristmasComponent } from './admin-christmas/admin-christmas.component';
// import { AdminDonateComponent } from './admin-donate/admin-donate.component';
// import { AdminContactComponent } from './admin-contact/admin-contact.component';
// import { AdminChildrenComponent } from './admin-children/admin-children.component';
// import { AppMaterialModule } from '../core/material/app.material';

@NgModule({
  imports: [
    CommonModule,
    TreeModule,
    // AppMaterialModule
  ],
  exports: [
    TreeModule,
  ],
  providers: [
    NodeService,
  ],
  declarations: [
    // AdminProjectsComponent
    // , AdminCenterComponent
    // , AdminChristmasComponent
    // , AdminDonateComponent
    // , AdminContactComponent
    // , AdminChildrenComponent
  ],
})
export class BackofficeModule { }
