import { Component, OnInit, HostListener } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ImagesService } from '../services/ImagesService';
import { Observable } from 'rxjs';
import { ImageModel } from '../models/ImageModel';
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { SpinnerService } from '../services/SpinnerService';
import { CommonService } from '../services/CommonService';
import { PageService } from '../services/PagesServices';
import { PageModel } from '../models/PageModel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
@HostListener('window:resize', ['$event'])
export class HomeComponent implements OnInit {
  private images = [];
  private motto = 'test';
  // private width: any = '70%';
  // private height: any = '700px';
  constructor(private imagesService: ImagesService
    , private spinnerService: SpinnerService
    , private pageService: PageService
  ) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.imagesService.getImages().subscribe(
      res => {
        this.images = res;
      });

    this.pageService.getPageById(1).subscribe(
      res => {
        this.motto = res[0].Motto;
      }
    );

    this.spinnerService.hide();
  }
  onResize(event) {
    // this.width = '70%';
    // this.height = event.target.innerHeight;
  }
}
