import { Component, OnInit, ViewChild } from '@angular/core';
import { TreeNode, MenuItem } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { NodeService } from 'src/app/core/shared/node.service';
import { AdminHomeComponent } from '../admin-home/admin-home.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
  // directives: [AdminHomeComponent]
})


export class HomeSettingsComponent implements OnInit {
  @ViewChild('expandingTree')

  expandingTree: Tree;
  nodes: TreeNode[];
  lazyFiles: TreeNode[];
  selectedFile: TreeNode;
  loading: boolean;
  // , private messageService: MessageService
  constructor(private nodeService: NodeService, private router: Router) { }
  // .then((files: IPageSettings) => { this.nodes = files.data; });

  ngOnInit() {
    this.loading = true;
    this.nodeService.getFiles()
      .subscribe(
        nodes => {
          //  console.log(files);
          this.nodes = nodes.data;
        });
    this.loading = false;

  }

  nodeSelect(event) {
    if (event.node.routerLink && event.node.routerLink.trim !== '') {
      this.router.navigate([event.node.routerLink]);  // {4}
      // this.messageService.add({ severity: 'info', summary: 'Node Selected', detail: event.node.label });
    }

  }

  nodeUnselect() {
    // this.selectedFile = event.node;
    // this.messageService.add({ severity: 'info', summary: 'Node Unselected', detail: event.node.label });
  }

  nodeExpandMessage() {
    // this.messageService.add({ severity: 'info', summary: 'Node Expanded', detail: event.node.label });
  }

  nodeExpand(event) {
    if (event.node) {
      // in a real application, make a call to a remote url to load children of the current node and add the new nodes as children
      // this.nodeService.getLazyFiles().then(nodes => event.node.children = nodes);
    }
  }

  viewFile() {
    // this.messageService.add({ severity: 'info', summary: 'Node Selected with Right Click', detail: file.label });
  }

  unselectFile() {
    this.selectedFile = null;
  }

  expandAll() {
    this.loading = true;

    this.nodes.forEach(node => {
      this.expandRecursive(node, true);
    });
    this.loading = false;

  }

  collapseAll() {
    this.loading = true;

    this.nodes.forEach(node => {
      this.expandRecursive(node, false);
    });
    this.loading = false;

  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
}
