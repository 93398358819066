import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/frontoffice/services/AuthenticateService';
import { Router, NavigationEnd } from '@angular/router';
import { User } from 'src/app/frontoffice/models/user';
import { MatSidenav } from '@angular/material';
import { SideNavService } from 'src/app/frontoffice/services/SideNavService';
import { Subscription } from 'rxjs';
import { SideNavComponent } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @Output() navToggle = new EventEmitter<boolean>();

  private subscription: Subscription;
  currentUser: User;
  isLoggedIn: any;
  opened: boolean;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private sideNavService: SideNavService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.sidenav.close();

        this.currentUser = this.authService.currentUserValue;
        if (this.currentUser) {
          this.isLoggedIn = this.authService.isLoggedIn; // {2}
          return true;
        }
      }
    });
  }

  ngOnInit() {
    this.subscription = this.sideNavService.sideNavActive.subscribe((val: boolean) => {
      debugger
      this.opened = val;
    });
  }

  navOpen() {
    this.navToggle.emit(true);
  }

  // tslint:disable-next-line:no-shadowed-variable
  toggle() {
    debugger
    // this.sidenav.toggle();
    // debugger
    this.opened = !this.opened;
    // if (!this.opened) {
    //   this.sideNavService.show();
    // } else {
    //   this.sideNavService.hide();

    // }

  }
}
