import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { SpinnerService } from './SpinnerService';
import { Settings } from '../../core/app.settings';
import { catchError, map, finalize } from 'rxjs/operators';
import { PageModel } from '../models/PageModel';
import { CommonService } from './CommonService';

@Injectable()
export class PageService implements OnInit, OnDestroy {
    private pages: PageModel[];
    private settings = new Settings();
    public headers: HttpHeaders = new HttpHeaders();

    constructor(private http: HttpClient
        , private spinnerService: SpinnerService
        , private commonService: CommonService) {
    }

    ngOnDestroy(): void {
    }
    ngOnInit(): void {
    }

    addPage(page: PageModel): Observable<PageModel[]> {
        if (this.spinnerService) {
            this.spinnerService.show();
        }
        return this.http.post(this.settings.pagesUrl, { data: page })
            .pipe(map((res: PageModel) => {
                this.pages.push(res);
                return this.pages;
            }),
                catchError(this.commonService.handleError));
    }
    getPageById(id: number): Observable<PageModel> {
        if (this.spinnerService) {
            this.spinnerService.show();
        }
        const params = new HttpParams()
            .set('Id', id.toString());

        return this.http.get(this.settings.pagesUrl, { params: params })
            .pipe(
                map((res: PageModel) => {
                    if (this.spinnerService) {
                        this.spinnerService.hide();
                    }
                    return res;
                }), catchError(this.commonService.handleError));

    }
    getPages(): Observable<PageModel[]> {
        if (this.spinnerService) {
            this.spinnerService.show();
        }
        return this.http.get(this.settings.pagesUrl)
            .pipe(
                map((res: PageModel[]) => {
                    this.pages = res; // res['data'];
                    if (this.spinnerService) {
                        this.spinnerService.hide();
                    }
                    return this.pages;
                }), catchError(this.commonService.handleError));

    }

    deletePage(id: number): Observable<PageModel[]> {
        if (this.spinnerService) {
            this.spinnerService.show();
        }
        const deleteUrl = this.settings.pagesUrl;

        const params = new HttpParams()
            .set('Id', id.toString());

        return this.http.delete(deleteUrl, { params: params })
            .pipe(map(res => {
                const filteredPages = this.pages.filter((page) => {
                    return +page['Id'] !== +id;
                });
                return this.pages = filteredPages;
            }),
                catchError(this.commonService.handleError));
    }

    updatePage(pageModel: PageModel): Observable<PageModel[]> {
        if (this.spinnerService) {
            this.spinnerService.show();
        }

        const url = this.settings.pagesUrl;
        debugger

        return this.http.put(url, { data: pageModel })
            .pipe(map((res) => {
                this.spinnerService.hide();

                const page = this.pages.find((item) => {
                    return +item['id'] === +pageModel['id'];
                });
                if (page) {
                    page.Motto = pageModel.Motto;
                    page.Text1 = pageModel.Text1;
                    page.Text2 = pageModel.Text2;
                    page.Text3 = pageModel.Text3;
                }
                return this.pages;
            }),
                catchError(this.commonService.handleError));
    }
    updateOnePage(pageModel: PageModel): Observable<PageModel> {
        if (this.spinnerService) {
            this.spinnerService.show();
        }
        const url = this.settings.pagesUrl;
        // let page = new PageModel;
        return this.http.put(url, { data: pageModel })
            .pipe(map((res) => {
                // page = this.pages.find((item) => {
                //     return +item['id'] === +pageModel['id'];
                // });
                this.spinnerService.hide();

                if (pageModel) {
                    pageModel.Motto = pageModel.Motto;
                    pageModel.Text1 = pageModel.Text1;
                    pageModel.Text2 = pageModel.Text2;
                    pageModel.Text3 = pageModel.Text3;
                    pageModel.Content = pageModel.Content;

                }
                return pageModel;
            }),
                catchError(this.commonService.handleError));
    }
}
