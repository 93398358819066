import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { ILoaderState } from '../interfaces/ILoaderState';

@Injectable()
export class SpinnerService {
    private loaderSubject = new Subject<ILoaderState>();
    public spinnerActive: EventEmitter<Boolean>;
    loaderState = this.loaderSubject.asObservable();
    constructor() {
        this.spinnerActive = new EventEmitter();
    }

    show() {
        const state = this.loaderState.subscribe(res => {
            return res;
        });

        if (!state) {
            this.loaderSubject.next(<ILoaderState>{ show: true });
            this.spinnerActive.emit(true);
        }

    }
    hide() {
        const state = this.loaderState.subscribe(res => {
            return res;
        });

        if (state) {
            this.loaderSubject.next(<ILoaderState>{ show: false });
            this.spinnerActive.emit(false);
        }
    }
}
