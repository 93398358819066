import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, filter, switchMap } from 'rxjs/operators';
import { UserService } from '../services/UserService';
import { Observable, Subscription } from 'rxjs';
import { User } from '../models/user';
import { DataSource } from '@angular/cdk/collections';
import { SpinnerService } from '../services/SpinnerService';
import { PageService } from '../services/PagesServices';
import { PageModel } from '../models/PageModel';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy, AfterViewInit {

  private success: string;
  private error: string;
  private editorContent: '';
  private aboutPage: PageModel;

  constructor(private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
    ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    // about page
    this.pageService.getPageById(3).subscribe(
      res => {
        this.aboutPage = res[0];
        this.editorContent = res[0].Content.toString();
      });
  }
  ngAfterViewInit(): void {
    this.spinnerService.hide();
  }
  ngOnDestroy() {
  }
}
