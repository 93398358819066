import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from './frontoffice/services/SpinnerService';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Dreptullaviata';
  private subscription: Subscription;

  constructor(
    private loaderService: SpinnerService) {
  }

  ngOnInit() {
  }
  ngOnDestroy() {
  }
}
