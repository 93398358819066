import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { ILoaderState } from '../interfaces/ILoaderState';

@Injectable()
export class SideNavService {
    private sideNavSubject = new Subject<ILoaderState>();
    public sideNavActive: EventEmitter<Boolean>;
    sideNavState = this.sideNavSubject.asObservable();
    constructor() {
        this.sideNavActive = new EventEmitter();
    }

    show() {
        debugger
        const state = this.sideNavState.subscribe(res => {
            return res;
        });

        if (!state) {
            this.sideNavSubject.next(<ILoaderState>{ show: true });
            this.sideNavActive.emit(true);
        }

    }
    hide() {
        debugger

        const state = this.sideNavState.subscribe(res => {
            return res;
        });

        if (state) {
            this.sideNavSubject.next(<ILoaderState>{ show: false });
            this.sideNavActive.emit(false);
        }
    }
}
