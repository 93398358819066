import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/frontoffice/models/PageModel';
import { PageService } from 'src/app/frontoffice/services/PagesServices';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-donate',
  templateUrl: './admin-donate.component.html',
  styleUrls: ['./admin-donate.component.scss']
})
export class AdminDonateComponent implements OnInit {

  public options: Object = {
    charCounterCount: true,
    // Set the image upload parameter.
    imageUploadParam: 'image_param',

    // Set the image upload URL.
    imageUploadURL: '../shared/upload.php', // 'assets/images/portfolio',

    // Additional upload params.
    imageUploadParams: { id: 'file' },

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'txt'],
    events: {
      'froalaEditor.initialized': function () {
        console.log('initialized');
      },
      'froalaEditor.image.beforeUpload': function (e, editor, images) {
        if (images.length) {
          debugger
          // Create a File Reader.
          const reader = new FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload = (ev) => {
            const result = ev.target['result'];
            editor.image.insert(result, null, null, editor.image.get());
            console.log(ev, editor.image, ev.target['result']);
          };
          // Read image as base64.
          reader.readAsDataURL(images[0]);
        }
      },
      'froalaEditor.image.uploaded': function (e, editor, response) {
        // Image was uploaded to the server.
      },
      'froalaEditor.image.inserted': function (e, editor, $img, response) {
        // Image was inserted in the editor.
      },
      'froalaEditor.image.replaced': function (e, editor, $img, response) {
        // Image was replaced in the editor.
      },
      'froalaEditor.image.error': function (e, editor, error, response) {
        // Bad link.
        if (error.code === 1) {
          console.log('Bad link');
          // Bad link.
        } else if (error.code === 2) {
          // // No link in upload response.
          console.log('No link in upload response.');
        } else if (error.code === 3) {
          // // Error during image upload.
          console.log('Error during image upload.');

        } else if (error.code === 4) {
          console.log('Parsing response failed.');

          // // Parsing response failed.

        } else if (error.code === 5) {
          // // Image too text-large.
          console.log('Image too text-large.');


        } else if (error.code === 6) {
          // // Invalid image type.
          console.log('Invalid image type.');

        } else if (error.code === 7) {
          // // Image can be uploaded only to same domain in IE 8 and IE 9.
          console.log('Image can be uploaded only to same domain in IE 8 and IE 9.');

        }

        // Response contains the original server response to the request if available.
      }
    }
  };

  private success: string;
  private error: string;
  private editorContent: '';
  private text1: '';

  private centerPage: PageModel;
  toastrConfig: { positionClass: string; };
  constructor(private pageService: PageService, private toastrService: ToastrService) {
    this.toastrConfig = { positionClass: 'toast-top-center' };
  }

  ngOnInit() {
    this.getData();
  }
  getData() {
    // about page
    this.pageService.getPageById(7).subscribe(
      res => {
        this.centerPage = res[0];
        this.editorContent = res[0].Content.toString();
        this.text1 = res[0].Text1.toString();

      });
  }

  updatePage(event, page: PageModel) {
    this.centerPage.Content = this.editorContent;
    this.centerPage.Text1 = this.text1;

    this.pageService.updateOnePage(this.centerPage)
      .subscribe((res) => {
        this.centerPage = res;
        this.success = 'Updated successfully';

      },
        (err) => this.error = err);
    this.toastrService.success('Pagina a fost salvata cu succes.', 'Succes!', this.toastrConfig);

  }

}
