import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/UserService';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
import { ToastContainerDirective } from 'ngx-toastr';
import { Store } from '@ngrx/store';

import { AppState } from '../../core/store/app.state';
import { LogIn } from '../../core/store/actions/auth.actions';

import { SpinnerService } from '../services/SpinnerService';
import { AuthenticationService } from '../services/AuthenticateService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  user: User[];
  invalidLogin: boolean;


  form: FormGroup;                    // {1}
  private formSubmitAttempt: boolean; // {2}

  constructor(private router: Router,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService,
    private fb: FormBuilder,         // {3}
    private authenticationService: AuthenticationService, // {4}

    // private store: Store<AppState>
  ) {
    // toastrConfig.timeOut = 100;
  }

  ngOnInit() {
    // this.form = this.fb.group({     // {5}
    //   userName: ['', Validators.required],
    //   password: ['', Validators.required]
    // });
  }

  isFieldInvalid(field: string) { // {6}
    // return (
    //   (!this.form.get(field).valid && this.form.get(field).touched) ||
    //   (this.form.get(field).untouched && this.formSubmitAttempt)
    // );
  }

  onSubmit() {
    if (this.form.valid) {
      // this.authService.login(this.form.value); // {7}
      this.login(); // {7}
    }
    this.formSubmitAttempt = true;             // {8}
  }

  login(): void {
    const toastrConfig = { positionClass: 'toast-top-center' };
    this.authenticationService.login(this.username, this.password)
      .subscribe(data => {
        this.user = data;
        if (data.length > 0) {
          this.router.navigate(['home']);
          // this.spinnerService.hide();
        } else {
          this.toastrService.error('Ati tastat gresit username sau parola.', 'Username sau parola gresite!', toastrConfig);
          this.invalidLogin = true;
          // this.spinnerService.hide();
        }
      });
  }

  diagnostic() {
    // return JSON.stringify(this.model);
  }
}
