import { Component, OnInit } from '@angular/core';
import { PageModel } from '../../models/PageModel';
import { UserService } from '../../services/UserService';
import { SpinnerService } from '../../services/SpinnerService';
import { PageService } from '../../services/PagesServices';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  private success: string;
  private error: string;
  private editorContent: '';
  private articlePage: PageModel;

  constructor(private userService: UserService
    , private spinnerService: SpinnerService
    , private pageService: PageService
  ) { }

  ngOnInit() {
    this.getData();
  }
  getData() {
    // about page
    this.pageService.getPageById(3).subscribe(
      res => {
        this.articlePage = res[0];
        this.editorContent = res[0].Text1.toString();
      });
  }
  // ngAfterViewInit(): void {
  //   this.spinnerService.hide();
  // }
  // ngOnDestroy() {
  // }


}
