import { NgModule } from '@angular/core';

import {
    MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatCardModule, MatMenuModule
    , MatSlideToggleModule, MatFormFieldModule, MatInputModule, MatTableModule, MatRadioModule, MatDatepickerModule
    , MatNativeDateModule, MatTreeModule, MatExpansionModule, MatProgressSpinnerModule
} from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

@NgModule({
    exports: [
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatDatepickerModule,
        MatNativeDateModule,        // <----- import for date formating(optional)
        MatMomentDateModule,        // <----- import for date formating adapted to more locales(optional)
        MatTreeModule,
        MatExpansionModule
    ],
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatDatepickerModule,
        MatNativeDateModule,        // <----- import for date formating(optional)
        MatMomentDateModule,        // <----- import for date formating adapted to more locales(optional)
        MatTreeModule,
        MatExpansionModule
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    ],
})
export class AppMaterialModule { }

